<template>
    <div class="recordBox">
        <el-row :gutter="10">
            <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']" @click="showList = !showList"></div>
                <div class="bg-white" v-show="showList">
                    <el-descriptions title="档案分类"></el-descriptions>
                    <el-tree class="treeData" :data="treeData" :props="defaultProps" v-loading="treeLoading" highlight-current
                             node-key="id" ref="myTree" @node-click="selRecord">
                        <div slot-scope="{ node, data }" class="omit">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div>{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold']">
            <el-form class="searchForm query-form" size="small" ref="searchForm" :model="searchForm" label-width="100px" @keyup.enter.native="search(1)">
                    <el-form-item prop="archivesDataName" label="输入搜索：">
                        <el-input v-model.trim="searchForm.archivesDataName" maxlength="50" placeholder="题名"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="createBy" label="操作人：">
                        <el-select v-model="searchForm.createBy" filterable placeholder="请选择" clearable class="w100i">
                            <el-option v-for="item in userList" :key="item.createBy" :label="item.userName"
                                       :value="item.createBy"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="businessType" label="触发业务：">
                        <el-select v-model="searchForm.businessType" placeholder="请选择" clearable class="w100i">
                            <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in $dictUtils.getDictList('business_type')">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="businessModel" label="所属模块：">
                        <el-select v-model="searchForm.businessModel" placeholder="请选择" clearable class="w100i">
                            <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in $dictUtils.getDictList('business_model')">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="createStartDate" label="操作时间：" class="dateBox">
                        <el-date-picker v-model="createDate" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        type="datetimerange" clearable start-placeholder="开始时间"
                                        end-placeholder="结束时间" class="w100i">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label-width="20px">
                        <el-button type="primary" icon="el-icon-search" @click="search(1)" size="small">查询</el-button>
                        <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                    <div class="f_r">
                        <el-button size="small" v-show="hasPermission('record:log:export')" @click="exportDataAll()"><i class="icon-piliangdaochu iconfont buIcon"/>全部导出</el-button>
                        <el-button size="small" v-show="hasPermission('record:log:export')" @click="exportData()"><i class="icon-piliangdaochu iconfont buIcon"/>本页导出</el-button>
                    </div>
                </el-form>
                <div class="bg-white">
                    <el-table
                            :data="dataList"
                            v-loading="loading"
                            size="small"
                            height="calc(100vh - 429px)"
                            class="table" ref="multipleTable">
                        <el-table-column prop="createDate" label="操作时间" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="createBy" label="操作人" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="businessType" label="触发业务" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{$dictUtils.getDictLabel("business_type", scope.row.businessType ,'')}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="businessModel" label="所属模块" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{$dictUtils.getDictLabel("business_model", scope.row.businessModel ,'')}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="archivesDataName" label="题名" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="searchForm.current"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="searchForm.size"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                showList: true,
                treeData: [],
                treeLoading: false,
                defaultProps: {
                    children: 'children',
                    label: 'archivesBasicName'
                },
                searchForm: {
                    archivesBasicDataId: '',
                    archivesDataName: '',
                    createBy: '',
                    businessType: '',
                    businessModel: '',
                    createStartDate: '',
                    createEndDate: '',
                    current: 1,
                    size: 10,
                },
                createDate: [],
                userList: [],
                loading: false,
                dataList: [],
                total: 0,
            }
        },
        mounted() {
            this.searchTree()
            this.getUserList()
            this.search(1)
        },
        methods: {
            // 检索
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.createStartDate = this.createDate ? this.createDate[0] : ''
                p.createEndDate = this.createDate ? this.createDate[1] : ''
                this.$axios(this.api.record.logList, p, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取用户列表
            getUserList() {
                this.$axios(this.api.record.getCreateArchivesOperationLog).then(data => {
                    if (data.status) {
                        this.userList = data.data
                    }
                })
            },
            // 查询树状数据
            searchTree() {
                this.treeLoading = true
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.treeData = res.data
                        this.treeData.unshift({
                            archivesBasicName: '全部',
                            id: '',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.$refs.myTree.setCurrentKey(this.treeData[0].id);
                        });
                    }
                    this.treeLoading = false
                })
            },
            // 选择档案分类
            selRecord(val) {
                this.searchForm.archivesBasicDataId = val.id
                this.search(1)
            },
            // 导出
            exportData() {
                let idList = this.dataList.map(item => {
                    return item.archivesOperationLogId
                })
                this.exportExcel(this.api.record.exportArchivesOperationLog, idList, '档案操作日志', 'post')
            },
            // 导出全部
            exportDataAll() {
                const h = this.$createElement;
                this.$msgbox({
                    title: '温馨提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定导出所筛选后日志数据？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '注意：当所筛选的日志数据大于20000条时，只导出最新的20000条日志')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let p = JSON.parse(JSON.stringify(this.searchForm))
                    p.createStartDate = this.createDate ? this.createDate[0] : ''
                    p.createEndDate = this.createDate ? this.createDate[1] : ''
                    this.exportExcel(this.api.record.exportAllArchivesOperationLog, p, '档案操作日志', 'post')
                })
            },
            //重置
            reset() {
                this.createDate = []
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>
<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }

        .dateBox.el-form-item--small.el-form-item {
            display: inline-block;
            width: 45%;
        }
    }

    .treeData {
        height: calc(100vh - 286px) !important;
    }
    .el-col-1.el-col-unfold {
        height: calc(100vh - 206px) !important;
    }
</style>
